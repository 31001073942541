import React, { Component } from "react";

export const ColorContext = React.createContext();

export class ColorProvider extends Component {
  state = { color: "#fff" };

  render() {
    return (
      <ColorContext.Provider
        value={{
          state: this.state,
          setColor: value =>
            this.setState({
              color: value
            })
        }}
      >
        {this.props.children}
      </ColorContext.Provider>
    );
  }
}
