import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;

`;
export const Wrapper = styled.div`
  width: 100%;
  flex-direction: column;
`;
export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: "";
    width: 32px;
    height: 30px;
    background: ${(props) => props.color.background_color};
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

export const Description = styled.div`
  width: calc(100% - 64px);
  height: 112px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -50px;

  h2 {
    margin: 0 20px;
    font-family: "akademie";
    max-width: 240px;
    display: block;
    font-size: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
  }
`;

export const Content = styled.div`
  background: ${(props) => props.color.background_color || "#fff"};
  padding-top: 20px;
  padding-bottom: 40px;
  color: ${(props) => props.color.color || "#000"};
  p {
    font-size: 21px;
    line-height: 30px;
    margin: 50px 0 0;
  }
  figure {
    margin: 0;
    figcaption {
      font-size: 11px;
    }
    img {
      width: 100%;
    }
  }
`;

export const Overlay = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
`;
