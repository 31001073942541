import React, { useState, useRef, useEffect, useContext } from "react";
import Slick from "react-slick";
import styled from "styled-components";
import findKey from 'lodash/findKey';
import { useParams } from "react-router-dom";

import useWindowSize from "../../hooks/useWindow";
import { AppDataContext } from "../../context";

const SlickContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 56px 0;
  width: calc(${(props) => props.containerWidth}px + 4px);

  .slick-slider {
    &:after {
      content: "";
      height: 100%;
      width: 32px;
      background-color: #fff;
      display: block;
      position: absolute;
      height: 300px;
      top: 0;
      right: 0px;
      z-index: 1;
    }

    &:before {
      content: "";
      height: 100%;
      width: 32px;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 0;
      left: 0px;
      z-index: 1;
    }
  }

  .slick-list {
    padding: 56px 110px 26px 104px !important;
    height: 200px !important;
  }

  .slick-slide.slick-center img {
    transform: scale(2);
    transition: all 0.2s ease-in-out;
  }

  .slick-slide.slick-center a {
    position: absolute;
    margin-top: 40px;
    left: -90px;
    text-align: center;
    display: block;
    width: calc(100% + 140px);
    opacity: 1;
    transition: all 0.2s ease-in-out 0s;
    font-family: akademie;
    font-size: 22px;
    color: rgb(0, 0, 0);
    text-decoration: underline;
  }

  .slick-track {
    margin-top: 40px;
    margin-bottom: 50px;
    left: -5px;
  }

  .slide {
    position: relative;

    img {
      width: 60%;
      transition: all 0.2s ease-in-out;
    }
    a {
      opacity: 0;
    }
    &:focus {
      border: none;
      outline: 0;
    }
  }
`;

const Carousel = () => {
  const value = useContext(AppDataContext);
  const { id } = useParams();

  const size = useWindowSize();
  const calcCardInWidth = Math.round(size.width / "365");
  const containerWidth = size.width / calcCardInWidth;
  const [projectId, setProjectId] = useState();

  const [state, setState] = useState({
    nav1: null,
    nav2: null,
    nav3: null,
    nav4: null,
    nav5: null,
    nav6: null,
    nav7: null,
    nav8: null,
    nav9: null,
    nav10: null,
    nav11: null,
    nav12: null,
  });
  
  const slider1 = useRef();
  const slider2 = useRef();
  const slider3 = useRef();
  const slider4 = useRef();
  const slider5 = useRef();
  const slider6 = useRef();
  const slider7 = useRef();
  const slider8 = useRef();
  const slider9 = useRef();
  const slider10 = useRef();
  const slider11 = useRef();
  const slider12 = useRef();
  
  useEffect(() => {
    const getProjectObjectKey = findKey(value, {'id': parseInt(id)})
    const convertObjectKeytoSliderKey = (parseInt(getProjectObjectKey) + 1);

    if (value.length === convertObjectKeytoSliderKey) {
      setProjectId(0);
    } else {
      setProjectId(convertObjectKeytoSliderKey);
    }
  
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
      nav3: slider3.current,
      nav4: slider4.current,
      nav5: slider5.current,
      nav6: slider6.current,
      nav7: slider7.current,
      nav8: slider8.current,
      nav9: slider9.current,
      nav10: slider10.current,
      nav11: slider11.current,
      nav12: slider12.current,
    });
  }, []);

  const {
    nav1,
    nav2,
    nav3,
    nav4,
    nav5,
    nav6,
    nav7,
    nav8,
    nav9,
    nav10,
    nav11,
    nav12,
  } = state;

  if (value.length < 3) {
    return null;
  }

  const settings = {
    centerMode: true,
    infinite: true,
    focusOnSelect: true,
    arrows: false,
    centerPadding: "55px",
    slidesToShow: 2.5,
    adaptiveHeight: true,
    speed: 500,
    initialSlide: projectId 
  };

  return (
    <SlickContainer>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav2}
          ref={(slider) => (slider1.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>
            </div>
          ))}
        </Slick>
      </Container>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav3}
          ref={(slider) => (slider2.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>
            </div>
          ))}
        </Slick>
      </Container>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav4}
          ref={(slider) => (slider3.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>
            </div>
          ))}
        </Slick>
      </Container>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav5}
          ref={(slider) => (slider4.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>
            </div>
          ))}
        </Slick>
      </Container>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav6}
          ref={(slider) => (slider5.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>
            </div>
          ))}
        </Slick>
      </Container>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav7}
          ref={(slider) => (slider6.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>
            </div>
          ))}
        </Slick>
      </Container>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav8}
          ref={(slider) => (slider7.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>
            </div>
          ))}
        </Slick>
      </Container>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav9}
          ref={(slider) => (slider8.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>{" "}
            </div>
          ))}
        </Slick>
      </Container>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav10}
          ref={(slider) => (slider9.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>{" "}
            </div>
          ))}
        </Slick>
      </Container>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav11}
          ref={(slider) => (slider10.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>{" "}
            </div>
          ))}
        </Slick>
      </Container>
      <Container containerWidth={containerWidth}>
        <Slick
          asNavFor={nav1}
          ref={(slider) => (slider11.current = slider)}
          {...settings}
        >
          {value.map((item) => (
            <div class="slide">
              <img
                src={item._embedded["wp:featuredmedia"][0].source_url}
                alt={item.title.rendered}
              />
              <a href={`/project/${item.id}/${item.slug}`}>
                {item.title.rendered}
              </a>
            </div>
          ))}
        </Slick>
      </Container>
    </SlickContainer>
  );
};

export default Carousel;
