import React, { useEffect, useState } from "react";
import axios from 'axios';
import Loader from './components/loader'
export const AppDataContext = React.createContext('');

export const AppDataContextProvider = (props) => {
  const [post, setPost] = useState([]);
  const [endpoint, setEndpoint] = useState('posts?_embed');
  const [loading, setLoading] = useState(true);




  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/posts?_embed&per_page=100`)
      .then(res => {
        setPost(res.data);
        setLoading(false);
      })
  }, []);

    

  if (loading) {
    return <Loader />
  }

  return (
    <AppDataContext.Provider value={post}>
      {props.children}
    </AppDataContext.Provider>
  )
}