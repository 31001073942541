import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "akademie";
    src: url("/fonts/nb_akademie_regular-webfont.woff2") format('woff');
  }
  
  @font-face {
    font-family: "akademie_medium";
    src: url("/fonts/nb_akademie_medium-webfont.woff2") format('woff');
  }

  @font-face {
    font-family: "unicode8";
    src: url("/fonts/unibody8.woff") format('woff');
  }

  body {
    -webkit-backface-visibility: hidden;

    font-family: "akademie";
    margin: 0;
  }
`;

export const Footer = styled.div`
  height: 4px;
  background: black;
  width: 100%;
  bottom: 0;
  position: fixed;
  z-index: 999;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width 100%;
  overflow: hidden;

`

