import React from "react";
import styled from "styled-components";
import useWindowSize from '../../hooks/useWindow';

const TextMainContainer = styled.div`
 display: flex;
 flex-direction: row;
`

const TextContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`;
const TextContent = styled.div`

padding: 32px;
p, div {
  font-family: 'Adamina', serif;
  font-size: 21px;
  width: 100% !important;
  line-height: 30px;
  margin: 40px 0 0 !important;
  img {
    width: 100%;
    height: 100%;
    margin: 10px 0;

    // &:first-child {
    //   margin: -20px 0px 10px 0px !important
    // }


    &:last-child {
      margin-bottom: 20px 0;
    }
  }
}

.wp-caption-text {
  font-family: 'unibody8regular';
  font-size: 10px;
  margin: 0!important;
  padding: 0!important;
  margin-top: -15px !important;
  margin-bottom: -20px !important;
}


figure {
  margin: 0;
  
  figcaption {
    font-size: 11px;
    font-family: 'unibody8regular';
  }

  img {
    width: 100%;
  }
}
`;


const Text = props => {

  const size = useWindowSize();
  const calcCardInWidth = Math.round(size.width / '365');

  return (
    <TextMainContainer>
      {Array.apply(null, { length: calcCardInWidth }).map((e, i) => (
        <TextContainer>
          <TextContent>
            <div dangerouslySetInnerHTML={{ __html: props && props.value.text }} />
          </TextContent>
        </TextContainer>
      ))}
    </TextMainContainer>
  )
}

export default Text
