import React, { Component } from "react";

export const SliderContext = React.createContext();

export class SliderProvider extends Component {
  state = { slide: "1" };

  render() {
    return (
      <SliderContext.Provider
        value={{
          state: this.state,
          setSlide: value =>
            this.setState({
              slide: value
            })
        }}
      >
        {this.props.children}
      </SliderContext.Provider>
    );
  }
}
