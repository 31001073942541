import React from "react";
import styled from "styled-components";
import icon from "./icon.svg";
import arrow from "./arrow.svg";

const Arrow = styled.img`
  position: absolute;
  right: 22px;
  bottom: 24px;
`

const StyledCard = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  width: 100%;
  
  ${Arrow}:hover {
    opacity: 0.5
  }  
`;

const Avatar = styled.div`
  margin: 0 32px;
  background: ${props => props.color.background_color || "#fff"};  
  height: 310px;
  width: 100%;
  max-width: calc(100% - 64px);
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  &:before {
    content: '';    
    width: 30px;
    height: 30px;
    background: ${props => props.color.card_color || 'transparent'};
    position: absolute;
    left: 0;
    bottom: 0;
  }

img {
  position: absolute;
  top: 50px;
  width: 155px;
  height: 155px;
}
`;



const Title = styled.span`
  font-family: "akademie_medium";
  display: block;
  font-size: 22px;
  position: relative;
  z-index: 3;
`

const Description = styled.p`
  font-size: 16px;
  margin: 8px 0;
`

const Content = styled.div`
  background: #fff;
  height: 130px;
  width: calc(100% - 100px);
  top: -60px;
  position: relative;
  z-index: 2;

  h2 {
    font-family: "akademie, verdana";
    font-size: 22px;
  }
`;

const Inner = styled.div`
  padding: 16px 24px;
  height: calc(100% - 48px);
`

const Footer = styled.div`
  background: ${props => props.color.card_color || "#fff"};  
  height: 150px;
  width: 100%;
  top: -160px;
  position: relative;
  z-index: 0;
  margin-bottom: -120px;
`;

const Card = (props) => {
  const { colors, image, title, description, loop } = props;

  return (
    <StyledCard color={colors}>
      <Avatar color={colors}>
        <img src={image} alt="icon" />
      </Avatar>
      <Content>
        <Inner>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Arrow src={arrow} alt="icon" />
        </Inner>
      </Content>

      <Footer color={colors} />
    </StyledCard >
  );
}

export default Card;
