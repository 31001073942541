import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import styled from "styled-components";
import * as serviceWorker from "./serviceWorker";
import { AppDataContextProvider } from './context';

const AppContainer = styled.div`
  margin: 0 auto;
`

ReactDOM.render(
  <AppDataContextProvider>
    <AppContainer>
      <App />
    </AppContainer>
  </AppDataContextProvider>, document.getElementById("root"));
serviceWorker.unregister();
