import React, { useState, useEffect, useContext } from "react";
import triangle from "./triangle.svg";
import square from "./square.svg";
import {
  StyledNavigationContainer,
  StyledNavigation,
  Square,
  Triangle,
} from "./styled";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ColorContext } from "../../colorContext";
import useWindowSize from "../../hooks/useWindow";

const Navigation = () => {
  const [prevScrollpos, setprevScrollpos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState([]);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;
    setprevScrollpos(currentScrollPos);
    setVisible(visible);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const size = useWindowSize();
  const calcCardInWidth = Math.round(size.width / "365");
  const location = useLocation();

  const { state } = useContext(ColorContext);
  const {
    color: { background_color },
  } = state;

  return (
    <StyledNavigationContainer           
    visible={visible}
    >
      {Array.apply(null, { length: calcCardInWidth }).map((e, i) => (
        <StyledNavigation
          visible={visible}
          prevScrollpos={prevScrollpos}
          background={background_color}
        >
          <Link to="/">
            <Square
              isActive={location.pathname === "/"}
              src={square}
              alt="icon"
            />
          </Link>
          <Link to="/about">
            <Triangle
              isActive={location.pathname === "/about"}
              src={triangle}
              alt="icon"
            />
          </Link>
        </StyledNavigation>
      ))}
    </StyledNavigationContainer>
  );
};

export default Navigation;
