import styled from "styled-components";

export const StyledNavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  z-index: 8;
  top: ${(props) => (props.visible ? "0" : "-84px")};
`;

export const StyledNavigation = styled.div`
  left: 0;
  z-index: 999;
  background: ${(props) => props.background};
  height: 84px;
  width: 100%;
  display: flex;
  transition: all 0.2s ease-in-out;
  align-items: center;
  justify-content: space-between;
  opacity: ${(props) => props.prevScrollpos > 50 ? '1' : '0'};
`;

export const Triangle = styled.img`
  margin-right: 32px;
  opacity: ${(props) => (props.isActive ? "1" : "0.5")};
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 1;
    transition: 0.2s ease-in-out;
  }
`;

export const Square = styled.img`
  margin-left: 32px;
  opacity: ${(props) => (props.isActive ? "1" : "0.5")};
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 1;
    transition: 0.2s ease-in-out;
  }
`;

export const StyledNavigationItem = styled.a`
  display: block;
  width: 28px;
  height: 28px;
  margin-left: 35px;
  border-radius: 5px;
  background: #000;
`;