import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: ${(props) => props.color.background_color || "#fff"};
  color: ${(props) => props.color.color || "#000"};
`;

export const Header = styled.div`
  margin-top: 84px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 155px;
    height: 159px;
    object-fit: cover;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  flex-direction: row;
`;

export const Description = styled.div`
  width: calc(100% - 64px);
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 0 20px;
    font-family: "akademie_medium";

    max-width: 210px;
    display: block;
    font-size: 30px;
    text-align: center;
  }
`;

export const Content = styled.div`
  padding: 0 32px 56px 32px;

  p:first-child {
    margin: 0;
  }

  p {
    font-family: "Adamina", serif;
    font-size: 21px;
    line-height: 30px;
    margin: 40px 0 0;    
  }


  .head {
    display: block;
    margin-bottom: -10px;
  }

  p:last-child {
    font-size: 46px;
    line-height: 56px;
    font-family: 'akademie';
    margin-top: 40px !important;
  }

  a {
    color: inherit;
  }

  figure {
    margin: 0;
    figcaption {
      font-size: 10px;
    }
    img {
      width: 100%;
    }
  }
`;
