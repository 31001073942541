import React, { useState, Component, useContext, useEffect, useRef } from 'react';
import Slick from "react-slick";
import styled from "styled-components";
import useWindowSize from '../../hooks/useWindow';
import { ColorContext } from "../../colorContext";

const SlickContainer = styled.div`
display: flex;
flex-direction: row;

`
const Container = styled.div`
display: flex;
flex-direction: column;
width: calc(${props => props.containerWidth}px);

  .slide {
    img {
      width: 100%;      
      }
  }
  .slick-dots {
    text-align: left;
    margin-left: 32px;
    li {      
      font-size: 10px;
      &.slick-active {
        span {          
          opacity: 1;
          display: block;
        }
        
        .styled-dot {          
          border-bottom: 0; 
        }
      }
      .styled-dot {
        background-color: transparent;
        height: 30px;
        border-bottom: 1px solid ${props => props.color.color};
        
      }
        span {          
          opacity: 0;
          font-family: 'unibody8regular';
          font-size: 10px;
          display: none;
        }                            
      }
    }  
`
const Slider = props => {
  const size = useWindowSize();
  const calcCardInWidth = Math.round(size.width / '365');
  const containerWidth = size.width / calcCardInWidth;
  const [state, setState] = useState({ nav1: null, nav2: null, nav3: null, nav4: null, nav5: null, nav6: null, nav7: null, nav8: null, nav9: null, nav10: null, nav11: null, nav12: null });
  const { state: { color } } = useContext(ColorContext);
  const slider1 = useRef();
  const slider2 = useRef();
  const slider3 = useRef();
  const slider4 = useRef();
  const slider5 = useRef();
  const slider6 = useRef();
  const slider7 = useRef();
  const slider8 = useRef();
  const slider9 = useRef();
  const slider10 = useRef();
  const slider11 = useRef();
  const slider12 = useRef();

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
      nav3: slider3.current,
      nav4: slider4.current,
      nav5: slider5.current,
      nav6: slider6.current,
      nav7: slider7.current,
      nav8: slider8.current,
      nav9: slider9.current,
      nav10: slider10.current,
      nav11: slider11.current,
      nav12: slider12.current,
    });
  }, []);

  const { nav1, nav2, nav3, nav4, nav5, nav6, nav7, nav8, nav9, nav10, nav11, nav12 } = state;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    customPaging: function (i) {
      const slideNumber = i < 10 ? `0${i + 1}` : i + 1;
      return (
        <div class="styled-dot">
          <span>{slideNumber}</span></div>
      );
    },
  };

  const { value: { slider: slickData } } = props;

  return (
    <SlickContainer>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav2}
          ref={slider => (slider1.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav3}
          ref={slider => (slider2.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav4}
          ref={slider => (slider3.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav5}
          ref={slider => (slider4.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav6}
          ref={slider => (slider5.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav7}
          ref={slider => (slider6.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav8}
          ref={slider => (slider7.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav9}
          ref={slider => (slider8.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav10}
          ref={slider => (slider9.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav11}
          ref={slider => (slider10.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
      <Container color={color} containerWidth={containerWidth}>
        <Slick
          asNavFor={nav1}
          ref={slider => (slider11.current = slider)}
          {...settings}>
          {slickData.map((item) => (
            <div class="slide">
              <img src={item.url} alt={item.title} />
            </div>
          ))}
        </Slick>
      </Container>
    </SlickContainer>
  )
};

export default Slider;