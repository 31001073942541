import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Loader from "../../components/loader";
import useWindowSize from "../../hooks/useWindow";
import Types from "../../types";
import { ColorContext } from "../../colorContext";
import Carousel from "../../components/types/Carousel";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { useParams } from "react-router-dom";
import * as S from "./styled";
import { Helmet } from "react-helmet";

const Detail = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useInfiniteScroll(moreData);
  const [loading, setLoading] = useState(true);
  const size = useWindowSize();
  const calcCardInWidth = Math.round(size.width / "365");
  const { setColor } = useContext(ColorContext);

  const loadData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/wp-json/wp/v2/posts?include[]=${id}&_embed&per_page=100`
      )
      .then((res) => {
        setData([res.data[0]]);
        setLoading(false);
        setColor(res.data[0].acf);
      });
  };

  function moreData() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/wp-json/wp/v2/posts?include[]=${id}&_embed&per_page=100`
      )
      .then((res) => {
        setData([...data, res.data[0]]);
        setIsFetching(false);
        setLoading(false);
        setColor(res.data[0].acf);
      });
  }

  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const articleList = data.map((article, key) => {
    const { color, background_color, overlay, header } = article.acf;
    const colors = {
      color,
      background_color,
    };

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Jarrik Muller - {article.title.rendered}</title>
          <link rel="canonical" href={`http://www.jarrik.com/${article.id}/${article.slug}`} />
        </Helmet>
        <S.Container key={key}>
          <React.Fragment>
            {Array.apply(null, { length: calcCardInWidth }).map((e, i) => (
              <S.Wrapper>
                <S.Header color={colors}>
                  {overlay && <S.Overlay src={overlay.url} />}
                  <img src={header.url} />
                  <S.Description>
                    <h2>{article.title.rendered}</h2>
                  </S.Description>
                </S.Header>
              </S.Wrapper>
            ))}
          </React.Fragment>
        </S.Container>
        <S.Content color={colors}>
          {article.acf.content.map((block) => Types(block))}
        </S.Content>
        <Carousel />
      </>
    );
  });
  return articleList;
};

export default Detail;
