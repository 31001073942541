import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Loader from "../../components/loader";
import useWindowSize from "../../hooks/useWindow";
import { ColorContext } from "../../colorContext";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import Carousel from "../../components/types/Carousel";
import { Helmet } from "react-helmet";
import * as S from "./styled";

const About = () => {
  const size = useWindowSize();
  const calcCardInWidth = Math.round(size.width / "365");
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useInfiniteScroll(moreData);
  const [loading, setLoading] = useState(true);
  const { setColor } = useContext(ColorContext);

  const loadData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/pages/2?_embed`)
      .then((res) => {
        setData([res.data]);
        setLoading(false);
        setColor(res.data.acf);
      });
  };

  function moreData() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/pages/2?_embed`)
      .then((res) => {
        setData([...data, res.data]);
        setLoading(false);
        setIsFetching(false);
        setColor(res.data.acf);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const articleList = data.map((article, key) => {
    const { color, background_color } = article.acf;
    const { source_url } = article && article._embedded["wp:featuredmedia"][0];
    const colors = {
      color,
      background_color,
    };

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Jarrik Muller - About</title>
          <link rel="canonical" href="http://www.jarrik.com/about" />
        </Helmet>
        <S.Container key={key} color={colors}>
          <>
            {Array.apply(null, { length: calcCardInWidth }).map((e, i) => (
              <div>
                <S.Header>
                  <img src={source_url} />
                  <S.Description>
                    <h2>{article.title.rendered}</h2>
                  </S.Description>
                </S.Header>
                <S.Content>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: article.content.rendered,
                    }}
                  />
                </S.Content>
              </div>
            ))}
          </>
        </S.Container>
        <Carousel />
      </>
    );
  });
  return articleList;
};

export default About;
