import React from "react";
import Navigation from "./components/navigation";
import About from "./pages/About";
import Detail from "./pages/Detail";
import Home from "./pages/Home";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Footer, Container } from "./styled";
import { createGlobalStyle } from "styled-components";
import { SliderProvider } from "./sliderContext";
import akademie from "./fonts/nb_akademie_regular-webfont.woff2";
import akademie_medium from "./fonts/nb_akademie_medium-webfont.woff2";
import unibody8 from "./fonts/unibody8.woff2";
import { ColorProvider } from "./colorContext";
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'akademie';
  src: url(${akademie}) format('woff2');
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'akademie_medium';
  src: url(${akademie_medium}) format('woff2');
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'unibody8regular';
  src: url(${unibody8}) format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "akademie";
  margin: 0;
}
`;
function App() {
  return (
    <ColorProvider>
      <SliderProvider>
        <Container>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Jarrik Muller - Home</title>
            <link rel="canonical" href="http://www.jarrik.com" />
          </Helmet>
          <GlobalStyle />
          <Router>
            <Navigation />
            <Route exact path="/" component={Home} />
            <Route exact path="/project/:id/:slug" component={Detail} />
            <Route exact path="/about/" component={About} />
          </Router>
          <Footer />
        </Container>
      </SliderProvider>
    </ColorProvider>
  );
}

export default App;
