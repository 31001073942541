import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../components/loader";
import Card from "../../components/cards";
import Bio from "../../components/bio";
import useWindowSize from "../../hooks/useWindow";
import { ColorContext } from "../../colorContext";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import StyledLink from "./styled";

const Home = () => {
  const size = useWindowSize();
  const [data, setData] = useState([]);
  const calcCardInWidth = Math.round(size.width / "365");
  const [isFetching, setIsFetching] = useInfiniteScroll(moreData);
  const [loading, setLoading] = useState(true);
  const { setColor } = useContext(ColorContext);

  const renderBio = {
    'bio': 'bio'
  };

  const loadData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/posts?_embed&per_page=100 `)
      .then((res) => {
        setData([renderBio, ...res.data]);
        setLoading(false);
        setColor({ background_color: "#fff" });
      });
  };

  function moreData() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/posts?_embed&per_page=100 `)
      .then((res) => {
        const newData = res.data;
        setData([...data, renderBio, ...newData]);
        setIsFetching(false);
        setLoading(false);
        setColor({ background_color: "#fff" });
      });
  }

  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {data.map((card, key) => {
        if (card.bio) {
          return <Bio loop={calcCardInWidth} />
        }
        return (
          <StyledLink key={key} to={`/project/${card.id}/${card.slug}`}>
            {Array.apply(null, { length: calcCardInWidth }).map((e, i) => (
              <Card
                colors={card.acf}
                image={
                  card._embedded["wp:featuredmedia"] &&
                  card._embedded["wp:featuredmedia"][0].source_url
                }
                title={card.title.rendered}
                description={card.excerpt.rendered.replace(/<\/?[^>]+(>|$)/g, "")}
                loop={calcCardInWidth}
              />
            ))}
          </StyledLink>
        )
      })}
    </>
  )
};

export default Home;
