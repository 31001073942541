import React from "react";
import Text from "./components/types/Text";
import Slider from "./components/types/Slider";

const Types = {
  text: Text,
  slider: Slider,
};

export default block => {
  if (typeof Types[block.acf_fc_layout] !== "undefined") {
    return React.createElement(Types[block.acf_fc_layout], {
      key: block._uid,
      value: block,
    });
  }


  return console.warn(`The component ${block[0]} has not been created yet`)
};
