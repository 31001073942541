import React from "react";
import styled from "styled-components";

const BioContainer = styled.div`
display: flex;
flex-direction: row;
margin-bottom: 70px;

`
const BioContent = styled.div`
  display: flex; 
  width: 100%;
  flex-direction: column;
  margin: 90px 30px 0;
`;

const Heading = styled.h1`
  font-size: 30px;
  font-weight: 400;
  margin: 0;
`;

const HeadingTwo = styled.h2`
  font-size: 24px;
  font-weight: 100;
  margin: 0;
  margin-top: 19px;
`;

const Subtitle = styled.span`
  font-size: 24px;
  font-weight: 100;
  margin: 0;
`;

const Bio = ({ loop }) => {

  const year = new Date().getFullYear()

  return (  
  <BioContainer>
    {Array.apply(null, { length: loop }).map((e, i) => (
      <BioContent>
        <Heading>Jarrik Muller</Heading>
        <Subtitle>The Netherlands, 1983 </Subtitle>
        <HeadingTwo>Digital archive, {year}</HeadingTwo>
        <Subtitle>Pixels on screen</Subtitle>
      </BioContent>
    ))}
  </BioContainer>
  )
};

export default Bio;
