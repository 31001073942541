import React, { useContext } from "react";
import loader from './loader.svg';
import styled, { keyframes } from 'styled-components';
import { ColorContext } from "../../colorContext";
import useWindowSize from '../../hooks/useWindow';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;


const slideIn = keyframes`
  from {
    width: 0%;
    height: 100vh;
    margin-left: 30vw;
    transform: rotate(30deg);
  }

  to {
    width: 100%;
    height: 100vh;
    transform: rotate(0deg);

  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #004fe9;
  justify-content: center;
`


const LoadingRepeatContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledLoader = styled.img`
  width: 300px;
  height: 300px;
  animation: ${rotate} 2s linear infinite;
  
`

const Loader = () => {
  const size = useWindowSize();
  const calcCardInWidth = Math.round(size.width / '365');

  return (
    <LoadingRepeatContainer>
      {Array.apply(null, { length: calcCardInWidth }).map((e, i) => (
        <LoadingContainer >
          <StyledLoader src={loader} alt="loading" />
        </LoadingContainer>
      ))}
    </LoadingRepeatContainer>
  )
}


export default Loader;